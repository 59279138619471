var gaHelper = require("../tracking/googleanalytics");
var gaLogs = require("../tracking/trackingGAlogs");
var gtmHelper = require("../gtm/gtm");
var peTracking = require("../tracking/pushEngageTrackingEvent");
var util = require("../util");
/**
 * Function that does the call for fast buy click action
 **/
let fastBuyAction = (fastBuyUrl, form) => {
    $.spinner().start();
    $.ajax({
        url: fastBuyUrl,
        method: "POST",
        data: form,
        success: function success(data) {
            if (data.orderConfirmationModal) {
                try {
                    $("body").append(data.orderConfirmationModal);
                    $("#quickViewModal").modal("hide");
                    $("#confirmationFastBuyModal").modal("show");
                    var message = "New Purchase event Firing Fast Buy";
                    gaLogs.logGAEvent(data.steps.orderObj.orderNumber, message);
                    gaHelper.fireGAEvent("PURCHASE");
                    gtmHelper.fireGtmEvent("FASTBUY_PURCHASE", { order: data.steps.orderObj });
                    peTracking.pushEngageEvent("WEBTV_ACTIVITY_EVENT");
                    peTracking.pushEngageEvent("CATEGORY_ACTIVITY_EVENT");
                    var fbenabled= $("#facebookURLPurchase").data("isenabled");
                    if (fbenabled) {
                        var getIPApiUrl = $("#getIPApiUrl").val();
                        var fbcUrl= $("#facebookURLPurchase").val();
                        var fbp = util.getCookie("_fbp");
                        var fbc = util.getCookie("_fbc");
                        var fbcData = {
                            event_source_url: window.location.href,
                            client_user_agent: window.navigator.userAgent,
                            orderNumber: data.steps.orderObj.orderNumber,
                            fbp: fbp,
                            fbc: fbc
                        };
                        $.ajax({
                            url: getIPApiUrl,
                            method: "GET",
                            dataType: "json",
                            success: function (data) {
                                fbcData.client_ip_address = data.ip;
                                util.sendFBEvent(fbcUrl, fbcData);
                            },
                            error: function (error) {
                                console.log(error);
                            }
                        });
                    }
                } catch (e) {
                    console.error("error while tracking Fast Buy event "+e);
                }
            } else if (data.activateFastBuyModal) {
                $("#activateFastBuyModal").remove();
                $("body").append(data.activateFastBuyModal);
                $("#activateFastBuyModal").modal("show");
            } else if (data.failedFastBuyModal) {
                $("#failedFastBuyModal").remove();
                $("body").append(data.failedFastBuyModal);
                $("#failedFastBuyModal").modal("show");
            } else if (data.loginRequired) {
                $(".fast-buy-login-modal").remove();
                $("body").append(data.loginModal);
                $("#fastBuyLoginModal").modal("show");
            }
            $.spinner().stop();
        },
        error: function error() {
            $.spinner().stop();
        },
    });
};

/**
 * appends params to a url
 * @param {string} url - Original url
 * @param {Object} params - Parameters to append
 * @returns {string} result url with appended parameters
 */
function appendToUrl(url, params) {
    var newUrl = url;
    newUrl += (newUrl.indexOf("?") !== -1 ? "&" : "?") + Object.keys(params).map(function (key) {
        return key + "=" + encodeURIComponent(params[key]);
    }).join("&");

    return newUrl;
}

/**
 * Function that does the call for fast buy click action in case product has BudgetPay
 **/
let fastBuyActionBudgetPay = (fastBuyUrl, form, currentProduct, currentPrice) => {
    var urlParams = {
        budgetPayModalShow: true,
    };

    var url = appendToUrl(fastBuyUrl, urlParams);
    $.spinner().start();
    $.ajax({
        url: url,
        method: "POST",
        data: form,
        success: function success(data) {
            if (data.loginRequired) {
                $(".fast-buy-login-modal").remove();
                $("body").append(data.loginModal);
                $("#fastBuyLoginModal").modal("show");
                $.spinner().stop();
                return;
            }
            const currentPID = currentProduct.data("pid");
            $(".js-bugdet-modal-button").attr("data-pid", currentPID);
            const budgetModal = $(".js-budget-pay-modal");
            const installmentsNumber = currentProduct.data("installments-number");
            const installmentsAmount = currentProduct.data("installments-amount");

            budgetModal.find(".js-budget-modal-installments-number").html(installmentsNumber);
            budgetModal.find(".js-budget-modal-installments-amount").html(installmentsAmount);
            budgetModal.find("[data-product-price]").html(currentPrice);
            if (currentProduct.hasClass("shop-last-tile")) {
                $(".js-bugdet-modal-button").attr("data-shippingcharges", currentProduct.find(".shoplast-shipping-charges").data("shippingcharges"));
                var selectedOption = currentProduct.find("select[name='singleSelectDropdown']:visible").children("option:selected");
                if (selectedOption.length > 0 && selectedOption.val() == "Buy All") {
                    $(".js-bugdet-modal-button").attr("data-pid", selectedOption.data("productids"));
                    $(".js-bugdet-modal-button").attr("data-buyallmadiscount", selectedOption.data("buyalldiscount"));
                    budgetModal.find(".js-budget-modal-installments-amount").html((selectedOption.data("buyallprice")/installmentsNumber).toFixed(2));
                }
            } else {
                $(".js-bugdet-modal-button").removeAttr("data-buyallmadiscount");
                $(".js-bugdet-modal-button").removeAttr("data-shippingcharges");
            }
            budgetModal.modal("show");
            $.spinner().stop();
        },
        error: function error() {
            $.spinner().stop();
        },
    });
};

/**
 * Function that provides fast buy data
 **/
let buildFastBuyData = (currentButton, budgetPayValue) => {
    const fastBuyUrl = currentButton.data("url");
    let pid = currentButton.data("pid") ? currentButton.data("pid") : currentButton.closest(".product").data("pid");
    let buyAllMADiscount = currentButton.data("buyallmadiscount") || "0";
    let shippingCharges = currentButton.data("shippingcharges");
    pid = pid ? pid : currentButton.data("product-id");
    pid = pid ? pid : $(".pdp").data("pid");
    let qty = 1;
    let budgetPay = budgetPayValue;
    var isBuyAllGroup = $(".js-live-tv-product").attr("data-isbuyall");
    if ($(".ringboxpkgprod").length > 0) {
        var ringBoxctrlArr = $(".ringboxpkgprod .ringboximage.selected");
        var ringBoxId = ringBoxctrlArr.data("pid");
    }
    if (!pid) {
        pid = $(".js-live-tv-product").attr("data-product-id");
    }

    if ($("#quickViewModal.modal.show").length > 0 && $("#quantity").length == 1) {
        qty = parseInt($("#quantity").val(), 10);
    }

    if ($("#quickViewModal.modal.show").length > 0 && $(".product-quickview .quantity-input").length == 1) {
        qty = parseInt($(".product-quickview .quantity-input").val(), 10);
    }

    if ($(".product-detail").length > 0 && $("#quickViewModal.modal.show").length <= 0) {
        qty = parseInt($("#quantity-1").val(), 10);
    }

    if (currentButton.parents(".js-live-tv-product").length > 0 && $("#quickViewModal.modal.show").length <= 0) {
        qty = parseInt($("#productQuantityInput").val(), 10);
    }

    if (currentButton.parents(".shop-last-tile").length > 0 && currentButton.parents(".js-live-tv-product").length <= 0 && $("#quickViewModal.modal.show").length <= 0) {
        qty = 1;
        shippingCharges = currentButton.parents(".shop-last-tile").find(".shoplast-shipping-charges").data("shippingcharges");
        var selectedOption = currentButton.parents(".shop-last-tile").find("select[name='singleSelectDropdown']:visible").children("option:selected");
        if (selectedOption.length > 0 && selectedOption.val() == "Buy All") {
            pid = selectedOption.data("productids");
            buyAllMADiscount = selectedOption.data("buyalldiscount");
        }
    }

    const form = {
        pid: ringBoxId ? (pid.toString() + "," + ringBoxId.toString()) : pid,
        quantity: qty,
        budgetPay: budgetPay,
        isBuyAllGroup: isBuyAllGroup,
        buyAllMADiscount: buyAllMADiscount || "0",
        shippingCharges: shippingCharges >= 0 ? shippingCharges : ""
    };

    return {
        fastBuyUrl: fastBuyUrl,
        form: form
    };
};

module.exports = {
    fastBuy: function () {
        $(document).on("click fastbuy:login", ".fast-buy-button", function () {
            const currentButton = $(this);
            const currentProduct = currentButton.closest(".product").length > 0 ? currentButton.closest(".product") : currentButton.closest(".product-detail");

            var $productContainer = $(this).closest(".product-detail");

            if ($productContainer.find(".attribute .size-value").length > 0 && $productContainer.find(".attribute .size-value").closest(".selected").length < 1) {
                if ($productContainer.find(".size-selection-error").length > 0) {
                    $productContainer.find(".size-selection-error").removeClass("d-none");
                    var sizeErrorElement = $productContainer.find(".price-container .js-formatted-price")[0];
                    sizeErrorElement.scrollIntoView({ behavior: "smooth" });
                }
                return;
            }

            if (currentProduct.find(".sold-out-badge").length > 0 && currentProduct.find(".sold-out-badge").is(":visible")) {
                $("#stockErrorModal").modal("show");
                return false;
            }

            let currentPrice;
            if (currentProduct.find(".js-formatted-price").length) {
                currentPrice = currentProduct.find(".js-formatted-price").html().trim();
            } else {
                currentPrice = currentProduct.find("#liveTvProductCurrentPrice").html().trim();
            }

            if (currentProduct.find(".clearance-price-label").length) {
                var price = currentProduct.find(".clearance-price-label").next(".js-formatted-price");
                if (price.length) {
                    currentPrice = price.html().trim();
                }
            }

            let isBudgetPay;

            if (currentProduct.hasClass("js-live-tv-product")) {
                var isVariantSelected = require("../liveTv/liveTvProductsDetails").isVariationSelectionOk();
                if (!isVariantSelected) {
                    var $variantSelectionErrorModal = $("#variantSelectionErrorModal");
                    $(".live-tv-shop-last-section").addClass("remove-wishlist-css");
                    $variantSelectionErrorModal.modal("show");
                    return;
                }
            }
            if (currentProduct.hasClass("js-live-tv-product")) {
                isBudgetPay = currentProduct.find("#budgetPayCheckbox").is(":checked") && (currentButton.closest("[data-budget-pay]").length > 0 || currentProduct.find("[data-budget-pay]").length > 0);
            } else {
                if (currentProduct.hasClass("product-detail")) {
                    isBudgetPay = currentButton.closest("[data-budget-pay]").length > 0 || currentProduct.closest("[data-budget-pay]").length > 0;
                } else {
                    isBudgetPay = currentButton.closest("[data-budget-pay]").length > 0 || currentProduct.find("[data-budget-pay]").length > 0;
                }
            }
            const callData = buildFastBuyData(currentButton, isBudgetPay);
            const form = callData.form;
            const fastBuyUrl = callData.fastBuyUrl;

            if (isBudgetPay && !currentProduct.hasClass("js-live-tv-product")) {
                fastBuyActionBudgetPay(fastBuyUrl, form, currentProduct, currentPrice);
            } else {
                fastBuyAction(fastBuyUrl, form);
            }

        });

        $(document).on("click", ".js-bugdet-modal-button", function () {
            const currentButton = $(this);

            $(".js-budget-pay-modal").modal("hide");

            let callData;

            if ($("input[name=optionsRadios2]:checked").val() === "option2") {
                callData = buildFastBuyData(currentButton, true);
            } else {
                callData = buildFastBuyData(currentButton, false);
            }

            const form = callData.form;
            const fastBuyUrl = callData.fastBuyUrl;

            fastBuyAction(fastBuyUrl, form);
        });

        $(document).on("click", ".js-fast-buy-new-customer", function (e) {
            e.preventDefault();
            const currentModal = $(this).closest(".fast-buy-login-modal");
            const currentPid = currentModal.find("[data-guest-current-pid]").val();
            const redirectUrl = currentModal.find(".guest-checkout-login").data("redirect");
            const quickViewModal = $("body").find("#quickViewModal");
            const currentProduct = $("[data-pid='"+currentPid+"']");
            const isBudgetPay = currentProduct[0].dataset.installmentsNumber;

            if (isBudgetPay) {
                const budgetModal = $(".js-budget-pay-modal-guest");
                // budgetModal.modal("show");
                if (quickViewModal.length > 0) {
                    quickViewModal.modal("hide");
                }
                currentModal.modal("hide");
                setTimeout(function () {budgetModal.modal("show");}, 20);

                const installmentsNumber = currentProduct.data("installments-number");
                const installmentsAmount = currentProduct.data("installments-amount");
                const currentPrice = currentProduct.find(".js-formatted-price").html();

                budgetModal.find(".js-budget-modal-installments-number").html(installmentsNumber);
                budgetModal.find(".js-budget-modal-installments-amount").html(installmentsAmount);
                budgetModal.find("[data-product-price]").html(currentPrice);
            } else {
                window.location = redirectUrl;
            }
        });

        $(document).on("click", ".js-bugdet-modal-button-guest", function () {
            const currentButton = $(this);
            const setBudgetPayURL = currentButton.data("url");
            let activateBudgetPay = false;

            if ($("input[name=optionsRadios]:checked").val() === "option2") {
                activateBudgetPay = true;
            }

            $.spinner().start();
            $.ajax({
                url: `${setBudgetPayURL}?budgetPay=${activateBudgetPay}`,
                method: "POST",
                success: function success(data) {
                    if (data && data.redirectUrl) {
                        window.location = data.redirectUrl;
                    }
                },
                error: function error() {
                    $.spinner().stop();
                },
            });
        });

        $(document).on("click", ".js-fast-buy-what-is-this", function () {
            $("#whatIsFastBuyModal").modal("show");
        });

        $(document).on("click", ".live-tv-what-is-fast-buy", function () {
            $(this).children(".js-live-tv-what-is-fast-buy").removeClass("d-none");
        });

        $(document).on("click", ".js-live-tv-info-close", function (e) {
            e.stopImmediatePropagation();
            $(".js-live-tv-what-is-fast-buy").addClass("d-none");
        });


        $(document).on("click", ".js-what-is-fast-buy-close", function () {
            $("#whatIsFastBuyModal").modal("hide");
        });

        $("body").on("hidden.bs.modal", "#whatIsFastBuyModal", function () {
            const quickViewModal = $("#quickViewModal");

            if (quickViewModal.length > 0 && quickViewModal.hasClass("show")) {
                $("body").addClass("modal-open");
            }
        });

        $("body").on("hidden.bs.modal", "#confirmationFastBuyModal", function () {
            var fastBuyProductId = urlParam("fastBuyProductId");
            if (fastBuyProductId) {
                var cleanUrl = getCleanUrl(window.location.href);
                window.location.href = cleanUrl;
            } else {
                window.location.reload();
            }
        });
    }
};

/**
 * Remove "fastBuyProductId" from URL param and return the URL
 * @param {string} url - url to be cleaned
 * @returns {string} clean url
 */
function getCleanUrl(url) {
    return url.replace(/#.*$/, "").replace(/\?.*$/, "");
}

/**
 * searches for provided parameter in current path
 * @param {string} name
 * @returns parameter value or null
 */
function urlParam(name) {
    //added eslint ignore because it didn't like \? regex
    //eslint-disable-next-line
    var results = new RegExp("[\?&]" + name + "=([^&#]*)").exec(window.location.href);
    if (results == null) {
        return null;
    }
    return decodeURI(results[1]) || 0;
}
